* {
  box-sizing: border-box;
}
html,
body {
  min-height: 100%;
  background: #e4e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Common */
.card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  max-width: 300px;
  overflow-wrap: break-word;
  text-align: center;
}

.btn {
  padding: 14px;
  border-radius: 30px;
  border: 0;
  background-color: #6922ff;
  color: #f8f8f9;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.btn:hover {
  background-color: #601fe6;
}

.btn:active {
  transform: translateY(1px);
}

i {
  position: absolute;
  right: 0px;
  padding: 10px;
  pointer-events: none;
  font-style: normal;
  font-size: 12px;
}

input {
  border-top: #fff;
  border-left: #fff;
  border-right: #fff;
  border-bottom-width: 1px;
  border-bottom-color: #a8aaab;
  text-align: center;
  width: 100%;
  font-size: 12px;
}

input:focus {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Loader */
.loader,
.loader:before,
.loader:after {
  background: #6922ff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #6922ff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* Back button */
.back-button {
  background-color: #e4e5e5;
  color: #637481;
  padding: 4px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  font-weight: 700;
  margin-bottom: 12px;
  border-radius: 10px;
}

.back-button-arrow {
  font-size: 22px;
}

.back-button-text {
  font-size: 16px;
}

.back-button:hover {
  background-color: #fff;
}

/* Progress bar */
.progress-bar {
  width: 100%;
  height: 4px;
  /* background-color: #ddd; */
  border-radius: 20px;
}

.progress-bar-inner {
  height: 100%;
  background-color: #6922ff;
  border-radius: 20px;
  animation: progress-animation 1s linear;
  transition: width 1s ease-in-out;
}

@keyframes progress-animation {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

/* App */
.tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.tab {
  border: 0;
  background-color: #e4e5e5;
  cursor: pointer;
}

/* Lightning */
.lightning-header {
  text-align: center;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
}

.lightning-input-fields {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
}

#profile-pic {
  border-radius: 100px;
  margin-bottom: 10px;
}

#username {
  font-weight: 900;
  font-size: 12px;
}

#name {
  font-size: 14px;
  font-style: italic;
}

#tip-greeting {
  margin-top: 20px;
  font-weight: 700;
}

#amount {
  font-size: 30px;
  font-weight: 700;
  padding-right: 35px;
}

.amount-container {
  position: relative;
}

/* Lightning QR */
.lightning-qr-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  width: 300px;
  max-width: 300px;
  overflow-wrap: break-word;
  text-align: center;
}

.lightning-qr-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.connection-indicator-container {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
}

.connection-indicator {
  height: 10px;
  width: 10px;
  border-radius: 100px;
}

.lightning-qr-amount {
  font-size: 24px;
  font-weight: 500;
}

.lightning-qr-memo {
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
}

.qr-container {
  position: relative;
  display: inline-block;
}

.qr-user-profile {
  border: 2px solid white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 100px;
  margin-bottom: 10px;
}

.lightning-qr-user-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.lightning-qr-username {
  font-weight: 700;
}

.lightning-qr-tip-greeting {
  font-size: 14px;
}

/* Success */
.success-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.success-amount {
  font-size: 24px;
  font-weight: 500;
}

.success-memo {
  margin-top: 10px;
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-color: #1bd787;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 70px;
  color: #fff;
}

.success-transaction-id-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  text-align: left;
  width: 80%;
}

.success-transaction-id-title {
  font-weight: 700;
  font-size: 14px;
}

.success-transaction-id-subtitle {
  font-size: 14px;
}

/* Expired */
.expired-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.expired-amount {
  font-size: 30px;
  font-weight: 500;
}

.expired-text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.expired-text-title {
  font-size: 24px;
  font-weight: 700;
}
.expired-text-subtitle {
  font-size: 14px;
}

.expired-memo {
  margin-top: 10px;
}

/* BitcoinQR */
.bitcoin-address-value {
  margin: 20px 0;
}
